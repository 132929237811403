.showcase-entry-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 10vh;
}

.profile-image {
  height: 20vh;
  width: 20vh;
  border-radius: 90%;
}

.creator-bio {
  background-color: #31afb4;
  opacity: 70%;
  color: black;
  border-radius: 5%;
}

.creator-socials {
  display: flex;
  justify-content: center;
}

.socials-item {
  margin: 0 1vw;
  a {
    text-decoration: none;
  }
}

.creator-name {
  font-size: 2.5em;
  padding-bottom: 5px;
}

.deal-item {
  border: solid black 1px;
  height: 15vh;
  width: 20vh;
  margin-top: 1vh;
}

.deal-button {
  width: 50%;
  background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%) !important;
  color: white !important;
  align-self: flex-end;
  height: 7vh;
}

.deal-image {
  height: 75%;
  width: 100%;
}

.deals-and-discounts-grid {
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // justify-items: center;
}

.creator-support-buttons {
  justify-content: center;
  margin-left: 25%;
}

.support-button {
  width: 50%;
  margin-top: 5vh;
  margin-bottom: 5px;
}
