// .containing-nav-wrapper {
//   width: 100%;
//   background-color: red;
// }
.discoverr-navbar {
  display: flex;
  justify-content: space-between;
}

// .main-logo {
//   flex-grow: 2;
// }
