@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}


*{font-family:"Roboto", sans-serif}.App{text-align:center}a{text-decoration:none}.global-navbar{background-color:#00464c}.three-column-grid{display:grid;grid-template-columns:1fr 1fr 1fr;justify-items:center}.vertical-stack{display:flex;flex-direction:column}

.discoverr-navbar{display:flex;justify-content:space-between}

.showcase-entry-container{display:grid;grid-template-columns:1fr 1fr 1fr;padding-top:10vh}.profile-image{height:20vh;width:20vh;border-radius:90%}.creator-bio{background-color:#31afb4;opacity:70%;color:black;border-radius:5%}.creator-socials{display:flex;justify-content:center}.socials-item{margin:0 1vw}.socials-item a{text-decoration:none}.creator-name{font-size:2.5em;padding-bottom:5px}.deal-item{border:solid black 1px;height:15vh;width:20vh;margin-top:1vh}.deal-button{width:50%;background:linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%) !important;color:white !important;align-self:flex-end;height:7vh}.deal-image{height:75%;width:100%}.creator-support-buttons{justify-content:center;margin-left:25%}.support-button{width:50%;margin-top:5vh;margin-bottom:5px}

.form-vertical-batch{display:flex;flex-direction:column;margin:auto 35vw}.sign-up-button{background-color:blueviolet;color:whitesmoke;box-shadow:none;padding:4vh;border-radius:5%}.user-bio-field{white-space:pre-wrap}

.landing-primary-info{height:98vh;margin-top:-3vh}

