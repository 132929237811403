.form-vertical-batch {
  display: flex;
  flex-direction: column;
  margin: auto 35vw;
}

.sign-up-button {
  background-color: blueviolet;
  color: whitesmoke;
  box-shadow: none;
  padding: 4vh;
  border-radius: 5%;
}

.user-bio-field {
  white-space: pre-wrap;
}
